import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>CROWNS</h2>
      <h3>What are they?</h3>
      <p>The crown of a tooth is that part which is visible in the mouth. An artificial crown  is used to protect  and restore a broken, weakened or heavily filled tooth. They are also used to improve the appearance of discoloured or crooked teeth.</p>
      <p>Crowns are made of many different materials including metals and ceramics.</p>
      <h3>What will my dentist do?</h3>
      <p>The dentist will shape the tooth so that, when the artificial crown is fitted, it will be the same  size and shape as a normal  tooth.</p>
      <p>Preparation time will depend on how damaged the tooth is and whether it needs to be built up first.</p>
      <p>If the tooth has died or there isn't enough left to support the crown it may have to be root-filled first - this is sometimes called "removing the nerve". The crown is sometimes held in place by a peg or post in the root canal if a lot of the tooth is missing.</p>
      <p>Your dentist will use a soft mouldable material  to make  an exact impression of what remains of the tooth that is to be crowned and the adjacent teeth.</p>
      <p>A dental technician uses the impressions to make the crown.  A temporary crown made  of plastic  or metal is usually  put over the tooth until the permanent crown  is made.  You can chew on a temporary crown but it won't be as strong as the finished one.</p>
      <p>When the crown  is fitted, your dentist  may need to make small adjustments to make sure you can bite comfortably. The crown is tried on and then cemented into place.</p>
      <h3>What are the benefits?</h3>
      <p>A crown is strong and can look and feel exactly like a natural tooth. The colour and shape can be matched to your own teeth</p>
      <p>Depending on the strength of the tooth underneath, a crown can last for many years if you look after your mouth and teeth and the crown is not accidentally damaged.</p>
      <p>Crowns can also improve your appearance.</p>
    </LightboxLayout>
  )
}

export default Page